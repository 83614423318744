<template>
  <div class="-tool">
    <div class="c_main -pad-btm">
      <div class="inner">
        <nav class="c_pnkz">
          <ul class="c_pnkz_list">
            <li><a class="c_pointer" @click="$router.push({name:'Home'})">ホーム</a></li>
            <li><a class="c_pointer" @click="$router.push({name:'Tool'})">みんなの支援ツール</a></li>
						<li>{{ contents.name }}</li>
          </ul>
        </nav>
        <h1 class="c_main_ttl">みんなの支援ツール</h1>
        <article class="c_tool">
          <header class="c_tool_header">
            <figure class="c_tool_item_img">
              <img
                :src="`https://${jc3LearningDomain}${contents.image_path}`"
                alt
                width="90"
                height="90"
              />
            </figure>
            <div class="c_tool_item_cnt">
              <h2 class="c_tool_item_ttl" :class="{'-new': isNew(tool.created_at)}">
                <em>{{ contents.name }}</em>
              </h2>
              <div class="c_tool_item_eval">
                <div class="c_tool_item_eval_star">
                  <star-rating :value="contents.evaluation_stat.avg" :width="13" :height="13"></star-rating>
                </div>
                <span class="c_tool_item_eval_count">({{ contents.evaluation_stat.count }})</span>
              </div>
              <p class="c_tool_item_info">
                <span>ver {{ tool.version }}</span> （<time>{{ tool.created_at | formatDateDot }}</time>）
              </p>
              <p class="c_tool_item_disc">{{ contents.description }}</p>
              <ul class="c_tool_item_tags">
                <li v-for="tag in contents.tags" :key="tag.id">
                  <a @click.stop="searchTag(tag.text)">{{ tag.text }}</a>
                </li>
              </ul>
            </div>
            <div class="c_tool_item_dl">
              <a @click="fileDownload(tool.file_path)" class="c_btn c_tool_item_dl_btn">
                <span class="icon-download"></span>
                <br />{{ isLatest ? '最新版ダウンロード' : '旧Ver.ダウンロード'}}
              </a>
              <p class="c_tool_item_dl_size">ファイルサイズ {{ fileSize }}</p>
            </div>
          </header>
          <dl class="c_tool_info">
            <dt>作者</dt>
            <dd>{{ contents.author_name }}</dd>
            <dt>対応機種</dt>
            <dd>{{ tool.compatible }}</dd>
            <dt>お問い合わせ</dt>
            <dd>{{ contents.author_belonging }}</dd>
          </dl>
          <div class="c_tool_slide">
            <button class="c_tool_slide_arrow -l c_tool_slide_arrow_prev" type="button">
              <span class="icon-arrow-l"></span>
            </button>
            <div class="c_tool_slide_list">
              <swiper :options="swiperOption">
                <swiper-slide v-for="(path, idx) in tool.image_paths" :key="idx">
                  <a
                    class="c_tool_slide_item"
                    @click="showModal(`https://${jc3LearningDomain}${path.image_path}`)"
                  >
                    <img :src="`https://${jc3LearningDomain}${path.image_path}`" alt />
                  </a>
                </swiper-slide>
              </swiper>
            </div>
            <button class="c_tool_slide_arrow -r c_tool_slide_arrow_next" type="button">
              <span class="icon-arrow-r"></span>
            </button>
          </div>
          <section class="c_tool_sec" v-for="(d, index) in description" :key="index">
            <h3 class="c_tool_sec_ttl">{{ d.title }}</h3>
            <p
              class="c_tool_sec_text"
              v-html="d.contents"
            ></p>
          </section>
          <section class="c_tool_sec" v-if="oldTools.length">
            <h3 class="c_tool_sec_ttl">旧Ver.ダウンロード</h3>
            <ul class="c_tool_files">
              <li class="c_tool_files_item" v-for="t in oldTools" :key="t.id">
                <em>Ver {{ t.version }}</em>
                <time>{{ t.created_at | formatDateHyphen }}</time>
                <a class="-dl" @click="fileDownload(t.file_path)">
                  <span class="icon-download"></span> ダウンロード
                </a>
                <a class="-detail" @click="$router.push({name: 'ToolSingle', query: { ver: t.version }})">詳細を見る</a>
              </li>
            </ul>
          </section>
          <aside class="c_tool_pr" v-if="contents.pr">
            <h3 class="c_tool_pr_ttl">[ PR ]</h3>
            <div class="c_tool_pr_row">
              <figure class="c_tool_pr_img">
                <img
                  :src="`https://${jc3LearningDomain}${contents.pr.image_path}`"
                  alt
                  width="160"
                  height="160"
                />
              </figure>
              <div class="c_tool_pr_cnt">
                <h4 class="c_tool_pr_subttl">{{ contents.pr.title }}</h4>
                <p class="c_tool_pr_text">{{ contents.pr.contents }}</p>
              </div>
            </div>
          </aside>
          <dl class="c_eval">
            <dt v-if="!evaluated">このツールを評価する</dt>
            <dt v-else>評価済み</dt>
            <dd>
              <div id="star" class="c_eval_star">
                <star-rating v-model="contents.my_evaluation" :width="20" :height="20"></star-rating>
              </div>
              <button v-if="!evaluated" class="c_btn c_eval_submit" type="button" @click="evaluate">評価投稿</button>
              <button v-else class="c_txtlink -arrow sec_item_eval_update" @click="evaluate">評価を変更する</button>
            </dd>
          </dl>
          <div class="c_pageback">
            <a class="c_txtlink" @click="$router.push({name: 'Tool'})">一覧に戻る</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StarRating from "@/components/StarRating";
import { jc3LearningDomain } from "@/../config.json";

export default {
  components: {
    StarRating
  },
  props: ["id"],
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,

      contents: {
        evaluation_stat: {}
      },
      tools: [],
      tool: {},
      oldTools: [],

      swiperOption: {
        //loop: true,
        //autoplay: {
        //  delay: 3000,
        //  stopOnLastSlide: false,
        //  disableOnInteraction: false,
        //  reverseDirection: false
        //},
        slidesPerView: 4,
        navigation: {
          nextEl: ".c_tool_slide_arrow_next",
          prevEl: ".c_tool_slide_arrow_prev"
        },
        preventClicks: false,
        preventClicksPropagation: false,
        autoplayDisableOnInteraction: false
      },

      // star rating
      rating: undefined,
      evaluated: false //評価済みフラグ
    };
  },
  computed: {
    description() {
      if (this.tool.description) {
        const toolsDescription = JSON.parse(this.tool.description)
        return toolsDescription.map(o => {
          o.contents = o.content.replace(/\r?\n/g, '<br>')
          return o
        })
      } else {
        return []
      }
    },
    fileSize() {
      if (this.tool.file_size) {
        return this.formatBytes(this.tool.file_size)
      } else {
        return '0 Bytes'
      }
    },
    isLatest() {
      return this.tools.findIndex(t => t.version === this.tool.version) === 0
    }
  },
  created() {
    this.fetchContents();
    this.fetchTools();
  },
  watch: {
    '$route': function () {
      this.changeVersion();
    },
  },
  methods: {
    showModal(img_src) {
      this.$modal.show(
        {
          template: `
            <div><img :src="imgSrc" width="100%"></img></div>
          `,
          props: ["imgSrc"]
        },
        { imgSrc: img_src },
        {
          adaptive: true,
          height: "auto",
          style: "background: rgba(0, 0, 0, 0.9);"
        }
      );
    },
    fetchContents() {
      this.$authAxios
        .get(`/api/contents/${this.id}`)
        .then(response => {
          this.contents = response.data;

          if (response.data.my_evaluation) {
            this.evaluated = true
          } else {
            this.contents.my_evaluation = 3 //default
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    fetchTools() {
      this.loadingTools = true;

      this.$authAxios
        .get(`/api/contents/${this.id}/tools`)
        .then(response => {
          if (response.data.length) {
            this.tools = response.data
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTools = false;
          this.changeVersion();
        });
    },
    isNew(date) {
      // 1か月以内に作成されてればNEW
      return moment(date).isAfter(moment().subtract(1, "months"));
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
    
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
      const i = Math.floor(Math.log(bytes) / Math.log(k));
    
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    fileDownload(file_path) {
      this.loading = true
      this.$authAxios
        .get(`https://${jc3LearningDomain}${file_path}`, {responseType: 'blob'})
        .then(response => {
          const url = URL.createObjectURL( new Blob([response.data]), {type: response.headers['content-type']});
          const filename = response.config.url.split('/').slice(-1)

          const link = document.createElement("a");
          link.href = url;
          link.download = filename
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
          this.$authAxios.post('/api/history', {id: this.id})
        });
    },
    changeVersion() {
      if (this.$route.query.ver) {
        const idx = this.tools.findIndex(t => t.version === this.$route.query.ver)
        console.log(idx)
        if (idx >= 0) {
          this.tool = this.tools[idx]
          this.oldTools = this.tools.slice(idx+1)
        }
      } else {
        this.tool = this.tools[0]
        this.oldTools = this.tools.slice(1)
      }
    },
    searchTag(tag) {
      this.$router.push({name: 'Search', query: { tag: tag }})
    },
    evaluate() {
      this.$authAxios.post(`/api/contents/${this.contents.id}/evaluate`, {evaluation: this.contents.my_evaluation})
      .then(() => {
        this.fetchContents()
      })
    }
  }
};
</script>

<style scoped>
.swiper-slide {
  width: 245px !important;
}
</style>